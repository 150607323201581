@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600;900&display=swap');


body {
  font-family: 'Work Sans', sans-serif;
}
